




import { Component, Vue } from 'vue-property-decorator';

import NotFound from '@/components/sections/404.vue'

@Component({
  components: {
    NotFound
  }
})
export default class FourOhFour extends Vue {
  
}
